import React from 'react';
import { Switch, Route } from 'react-router';
import { PageNotFound } from 'pages';
import { ViewFerrets, ViewFerretsModuleStatus } from 'components/Ferrets';
import { Routes } from 'constants/index';

const PageFerrets: React.FC = () => (
  <Switch>
    <Route exact path={Routes.Ferrets} component={ViewFerrets} />
    <Route exact path={Routes.FerretsModuleStatus} component={ViewFerretsModuleStatus} />
    <Route path="*" component={PageNotFound} />
  </Switch>
);

export default PageFerrets;
