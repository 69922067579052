import { createSelector } from 'reselect';
import {
  loadflowEnvironmentOptionsSelector,
  loadflowFlowOptionsSelectorFactory,
  portfolioOptionsSelectorFactory,
  workflowTenantsOptionsSelectorFactory,
} from 'modules/options/selectors';
import { FetchOptionsPageParam } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.calculations;

export const ferretsSelector = createSelector(rootSelector, calculations => calculations.ferrets);

export const loadflowItemsSelector = createSelector(rootSelector, calculations => calculations.loadflowItems);

export const tableFerretsWaitForDependenciesSelectorFactory = ({
  environment,
}: {
  environment: Layouts.Filters['environment'];
}) =>
  createSelector(
    [
      loadflowEnvironmentOptionsSelector,
      workflowTenantsOptionsSelectorFactory(`${FetchOptionsPageParam.FERRETS}_${environment}`),
    ],
    (loadflowEnvironmentOptions, workflowTenantsOptions): boolean => {
      return Boolean(!loadflowEnvironmentOptions || !workflowTenantsOptions);
    }
  );

export const ferretsModuleStatusSelector = createSelector(
  rootSelector,
  calculations => calculations.ferretsModuleStatus
);

export const tableFerretsModulaStatusWaitForDependenciesSelectorFactory = ({
  environment,
  tenant,
}: {
  environment: Layouts.Filters['environment'];
  tenant: Layouts.Filters['tenant'];
}) =>
  createSelector(
    [
      workflowTenantsOptionsSelectorFactory(`${FetchOptionsPageParam.LOADFLOW}_${environment}`),
      portfolioOptionsSelectorFactory(tenant),
    ],
    (workflowTenantsOptions, portfolioOptions): boolean => {
      return Boolean(!workflowTenantsOptions || !portfolioOptions);
    }
  );

export const tableLoadflowWaitForDependenciesSelectorFactory = ({
  environment,
}: {
  environment: Layouts.Filters['environment'];
}) =>
  createSelector(
    [
      loadflowEnvironmentOptionsSelector,
      loadflowFlowOptionsSelectorFactory(environment),
      workflowTenantsOptionsSelectorFactory(`${FetchOptionsPageParam.LOADFLOW}_${environment}`),
    ],
    (loadflowEnvironmentOptions, loadflowFlowOptions, workflowTenantsOptions): boolean => {
      return Boolean(!loadflowEnvironmentOptions || !loadflowFlowOptions || !workflowTenantsOptions);
    }
  );
