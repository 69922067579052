import styled from 'styled-components';
import React, { useState, useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import SelectStatus from './SelectStatus';
import { FormInput } from '@utiligize/shared/components';
import { setDataStatusItemStatusAction } from 'modules/uptime/actions';

interface Props extends Uptime.DataStatusItem {
  id: string;
}

const StatusTableItem: React.FC<Props> = memo(
  ({ id, name, first_simulation_id, status, first_test_run_timestamp, tenant }) => {
    const dispatch: Shared.CustomDispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<string>('');
    const [nextStatus, setNextStatus] = useState<Type.DataStatus | null>(null);

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setMessage(event.target.value);
    }, []);

    const handleInputBlur = useCallback(() => {
      if (!nextStatus) return;
      setLoading(true);
      dispatch(
        setDataStatusItemStatusAction({
          name,
          first_simulation_id,
          first_test_run_timestamp,
          status: nextStatus,
          message,
        })
      ).finally(() => setLoading(false));
      setNextStatus(null);
      setMessage('');
    }, [dispatch, name, first_simulation_id, first_test_run_timestamp, message, nextStatus]);

    const handleKeyPress = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') handleInputBlur();
      },
      [handleInputBlur]
    );

    const handleStatusChange = useCallback(
      (option: Type.SelectOption<Type.DataStatus>) => setNextStatus(option.value),
      []
    );

    return (
      <OverlayTrigger
        trigger="click"
        show={Boolean(nextStatus)}
        rootClose
        overlay={
          <Popover id={id}>
            <Popover.Content>
              <FormInput
                size="sm"
                autoFocus
                labelKey="Explanation"
                name="explanation"
                value={message || ''}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyPress={handleKeyPress}
              />
            </Popover.Content>
          </Popover>
        }
      >
        <StyledSelectStatus
          value={nextStatus || status}
          onChange={handleStatusChange}
          isDisabled={loading}
          isLoading={loading}
        />
      </OverlayTrigger>
    );
  }
);

const StyledSelectStatus = styled(SelectStatus)`
  width: 110px;
`;

export default StatusTableItem;
