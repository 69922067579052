import styled from 'styled-components';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isSidebarOpenSelector } from 'modules/layouts/selectors';
import { closeSidebarAction, toggleSidebarAction } from 'modules/layouts';
import { BaseSidebar, SidebarAccordion } from '@utiligize/shared/components';
import {
  IconMonitor,
  IconActivity,
  IconCheckCircle,
  IconArchive,
  IconPercent,
  IconDataflow,
  IconMeter,
} from '@utiligize/shared/resources';
import { Routes, DefaultRoute } from 'constants/index';

interface Props {
  isMobile: boolean;
}

const Sidebar: React.FC<Props> = ({ isMobile }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const isSidebarOpen = useSelector(isSidebarOpenSelector);

  const toggleSidebar = useCallback((): Promise<void> => dispatch(toggleSidebarAction()), [dispatch]);

  const closeSidebar = useCallback((): Promise<void> => dispatch(closeSidebarAction()), [dispatch]);

  const renderMenu = () => (
    <>
      <SidebarAccordion
        isSidebarOpen={isSidebarOpen}
        labelKey="Uptime"
        dataMarker="sidebar_uptime"
        icon={<IconMonitor />}
        items={[
          {
            to: Routes.StatusOverview,
            labelKey: 'Status overview',
            icon: <IconActivity />,
            disabled: false,
          },
          {
            to: Routes.TestOverview,
            labelKey: 'Test overview',
            icon: <IconActivity />,
            disabled: false,
          },
          {
            to: Routes.FunctionalityTests,
            labelKey: 'Functionality tests',
            icon: <IconCheckCircle />,
            disabled: false,
          },
          {
            to: Routes.Data,
            labelKey: 'Data',
            icon: <IconArchive />,
            disabled: false,
          },
          {
            to: Routes.MeterDataIngestion,
            labelKey: 'Meter data ingestion',
            icon: <IconMeter />,
            disabled: false,
          },
        ]}
      />
      <SidebarAccordion
        isSidebarOpen={isSidebarOpen}
        labelKey="Calculations"
        dataMarker="sidebar_calculations"
        icon={<IconPercent />}
        items={[
          {
            to: Routes.Ferrets,
            labelKey: 'Ferrets',
            icon: <IconDataflow />,
            disabled: false,
          },
          {
            to: Routes.Loadflow,
            labelKey: 'Loadflow',
            icon: <IconDataflow />,
            disabled: false,
          },
          {
            to: Routes.VersionDetails,
            labelKey: 'Version details',
            icon: <IconArchive />,
            disabled: false,
          },
        ]}
      />
    </>
  );

  return (
    <BaseSidebar
      isMobile={isMobile}
      isSidebarOpen={isSidebarOpen}
      DefaultRoute={DefaultRoute}
      toggleSidebar={toggleSidebar}
      closeSidebar={closeSidebar}
      renderMenu={renderMenu}
      topLogo={<StyledLogo>admin</StyledLogo>}
    />
  );
};

const StyledLogo = styled.span`
  width: 120px;
  height: 53px;
  color: white;
  font-size: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

export default Sidebar;
