import { handleActions, combineActions } from 'redux-actions';
import {
  getVersionChartData,
  fetchFerretsAction,
  fetchFerretsModuleStatusAction,
  startFerretAction,
  cancelFerretAction,
  fetchLoadflowAction,
  startLoadflowAction,
  cancelLoadflowAction,
} from './actions';

const initialState: Calculations.Root = {
  ferrets: null,
  ferretsModuleStatus: null,
  loadflowItems: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(fetchFerretsAction, fetchFerretsModuleStatusAction, fetchLoadflowAction) as any]: {
    next: (state: Calculations.Root, action: Shared.ReduxAction<Partial<Calculations.Root>>): Calculations.Root => ({
      ...state,
      ...action.payload,
    }),
  },
};

export {
  getVersionChartData,
  fetchFerretsAction,
  fetchFerretsModuleStatusAction,
  startFerretAction,
  cancelFerretAction,
  fetchLoadflowAction,
  startLoadflowAction,
  cancelLoadflowAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
