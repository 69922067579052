import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  dataHistoryCountSelector,
  dataHistoryItemsSelector,
  tableDataHistoryWaitForDependenciesSelectorFactory,
} from 'modules/uptime/selectors';
import { fetchDataHistoryAction } from 'modules/uptime';
import { dateFormat } from '@utiligize/shared/utils';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';
import { paginationSelectorFactory } from 'modules/layouts/selectors';

function formatValue(str: string) {
  if (!str) return '-';
  if (!isNaN(Number(str))) return Number(str);
  try {
    const data = Object.entries(JSON.parse(str));
    return data.map(([key, value]: any) => (
      <React.Fragment key={key}>
        {key}: {value}
        <br />
      </React.Fragment>
    ));
  } catch {
    return 'Invalid JSON';
  }
}

const TableDataHistory: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_DATA_HISTORY;
  const paginationSelector = useMemo(() => paginationSelectorFactory(type), [type]);
  const { filters }: Layouts.Pagination = useSelector(paginationSelector);

  const items = useSelector(dataHistoryItemsSelector);
  const count = useSelector(dataHistoryCountSelector);

  const waitForDependenciesSelector = useMemo(
    () =>
      tableDataHistoryWaitForDependenciesSelectorFactory({
        tenant: filters?.tenant,
        portfolio: filters?.portfolio,
        scenario: filters?.scenario,
      }),
    [filters?.tenant, filters?.portfolio, filters?.scenario]
  );
  const waitForDependencies = useSelector(waitForDependenciesSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchDataHistoryAction(params)).then(
        (action: Shared.ReduxAction<{ dataHistoryItems: Uptime.Root['dataHistoryItems'] }>) =>
          action.payload?.dataHistoryItems
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      waitForDependencies={waitForDependencies}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 20vh - 307px)"
    >
      {(items || []).map(item => (
        <tr key={item.test_run_timestamp}>
          <td className="text-nowrap">{item.test_run_timestamp ? dateFormat(item.test_run_timestamp, 'L LT') : '-'}</td>
          <td>{formatValue(item.value)}</td>
          <td>{formatValue(item.diff)}</td>
          <td>{item.version_id}</td>
          <td>{item.simulation_id}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableDataHistory;
