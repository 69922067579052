import { createAction } from 'redux-actions';
import { AssetLifeAPI, PaginationType } from 'constants/index';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import {
  loadflowInvestmentScenarioOptionsHashSelector,
  loadflowPortfolioOptionsHashSelector,
  loadflowScenarioOptionsHashSelector,
  loadflowStepsOptionsHashSelector,
  portfolioOptionsHashSelector,
  scenarioOptionsHashSelector,
  workflowTenantsOptionsHashSelector,
  loadflowFlowOptionsHashSelector,
  loadflowFlowTypeOptionsHashSelector,
  dataHistoryTestOptionsHashSelector,
} from './selectors';

// ------------------------------------
// Internal Admin Options Actions
// ------------------------------------
export const fetchTenantOptionsAction = createAction(
  'options/FETCH_TENANTS',
  async () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<Options.Root, 'tenantsOptions'>> => {
      return AssetLifeAPI.get('monitoring/tenants').then((res: { data: string[] }) => {
        const types = [
          { type: PaginationType.ADMIN_DATA_HISTORY, required: true },
          { type: PaginationType.ADMIN_TEST_HISTORY, required: false },
        ];
        const options = res.data.map(item => ({ value: item, label: item }));

        // Automatically apply default values for necessary table filters
        types.forEach(({ type, required }) => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.tenant);
          // Set value if required, check and automatically change if set but doesn't exist
          if ((required && !filters?.tenant) || (filters?.tenant && !option)) {
            const modifier = { filters: { ...filters, tenant: options?.[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return { tenantsOptions: options };
      });
    }
);

export const fetchWorkflowTenantOptionsAction = createAction(
  'options/FETCH_WORKFLOW_TENANTS',
  async (page: Type.FetchOptionsPageParam, env: Layouts.Filters['environment']) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'workflowTenantsOptionsHash'>> => {
      return AssetLifeAPI.get('gcp_workflows/tenants_loadflow_dropdown', {
        params: { env: env === 'all' ? null : env, page },
      }).then((res: { data: { id: string; name: string }[] }) => {
        const types = [PaginationType.ADMIN_VERSION_DETAILS, PaginationType.ADMIN_FERRETS_MODULE_STATUS];
        const options = res.data.map(item => ({ value: item.id, label: item.name }));

        // Automatically apply default values for necessary table filters
        types.forEach(type => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.tenant);
          if (!filters?.tenant || !option) {
            const modifier = { filters: { ...filters, tenant: options?.[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          workflowTenantsOptionsHash: {
            ...workflowTenantsOptionsHashSelector(getState()),
            [`${page}_${env}`]: options,
          },
        };
      });
    }
);

export const fetchTagOptionsAction = createAction(
  'options/FETCH_TAGS',
  async () => (): Promise<Pick<Options.Root, 'tagsOptions'>> => {
    return AssetLifeAPI.get('monitoring/tags').then((res: { data: string[] }) => ({
      tagsOptions: res.data.map(item => ({ value: item, label: item })),
    }));
  }
);

export const fetchTestOptionsAction = createAction(
  'options/FETCH_TESTS',
  async () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<Options.Root, 'testsOptions'>> => {
      return AssetLifeAPI.get('monitoring/dashboard_test_list').then((res: { data: string[] }) => {
        const types = [PaginationType.ADMIN_TEST_HISTORY];
        const options = res.data.map(item => ({ value: item, label: item }));

        // Automatically apply default values for necessary table filters
        types.forEach(type => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.test);
          if (!filters?.test || !option) {
            const modifier = { filters: { ...filters, test: options?.[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          testsOptions: options,
        };
      });
    }
);

export const applyPortfolioFiltersAction = createAction(
  'options/APPLY_PORTFOLIO_FILTERS',
  async (options: Type.SelectOption[]) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): void => {
      const types = [
        { type: PaginationType.ADMIN_DATA_HISTORY, required: true },
        { type: PaginationType.ADMIN_TEST_HISTORY, required: false },
        { type: PaginationType.ADMIN_VERSION_DETAILS, required: true },
        { type: PaginationType.ADMIN_FERRETS_MODULE_STATUS, required: true },
      ];

      // Automatically apply default values for necessary table filters
      types.forEach(({ type, required }) => {
        const state: State.Root = getState();
        const { filters } = paginationSelectorFactory(type)(state);
        const option = options.find((i: Type.SelectOption) => i.value === filters?.portfolio);
        // Set value if required, check and automatically change if set but doesn't exist
        if ((required && !filters?.portfolio) || (filters?.portfolio && !option)) {
          const modifier = { filters: { ...filters, portfolio: options?.[0]?.value }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }
      });
    }
);

export const fetchPortfolioOptionsAction = createAction(
  'options/FETCH_PORTFOLIO',
  async (tenant: Layouts.Filters['tenant']) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'portfolioOptionsHash'>> => {
      return AssetLifeAPI.get('monitoring/portfolios', { params: { tenant } }).then((res: { data: string[] }) => {
        const options = res.data.map(item => ({ value: item, label: item }));
        dispatch(applyPortfolioFiltersAction(options));
        return {
          portfolioOptionsHash: {
            ...portfolioOptionsHashSelector(getState()),
            [String(tenant)]: options,
          },
        };
      });
    }
);

export const fetchScenarioOptionsAction = createAction(
  'options/FETCH_SCENARIO',
  async ({
    tenant,
    portfolio,
    key,
  }: {
    tenant: Layouts.Filters['tenant'];
    portfolio: Layouts.Filters['portfolio'];
    key: string;
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'scenarioOptionsHash'>> => {
      return AssetLifeAPI.get('monitoring/scenarios', { params: { tenant, portfolio } }).then(
        (res: { data: string[] }) => {
          const types = [
            { type: PaginationType.ADMIN_DATA_HISTORY, required: true },
            { type: PaginationType.ADMIN_TEST_HISTORY, required: false },
          ];
          const options = res.data.map(item => ({ value: item, label: item }));

          // Automatically apply default values for necessary table filters
          types.forEach(({ type, required }) => {
            const state: State.Root = getState();
            const { filters } = paginationSelectorFactory(type)(state);
            const option = options.find((i: Type.SelectOption) => i.value === filters?.scenario);
            // Set value if required, check and automatically change if set but doesn't exist
            if ((required && !filters?.scenario) || (filters?.scenario && !option)) {
              const modifier = { filters: { ...filters, scenario: options?.[0]?.value }, offset: 0 };
              dispatch(setPaginationAction({ type, modifier }));
            }
          });

          return {
            scenarioOptionsHash: {
              ...scenarioOptionsHashSelector(getState()),
              [key]: options,
            },
          };
        }
      );
    }
);

export const fetchLoadflowFlowOptionsAction = createAction(
  'options/FETCH_LOAD_FLOW_FLOW',
  async (env: Layouts.Filters['environment']) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'loadflowFlowOptionsHash'>> => {
      return AssetLifeAPI.get('gcp_workflows/flow_dropdown', { params: { env } }).then(
        (res: { data: { id: string; name: string }[] }) => {
          const types = [PaginationType.ADMIN_LOADFLOW];
          const options = res.data.map(item => ({ value: item.id, label: item.name }));

          // Automatically apply default values for necessary table filters
          types.forEach(type => {
            const state: State.Root = getState();
            const { filters } = paginationSelectorFactory(type)(state);
            const option = options.find((i: Type.SelectOption) => i.value === filters?.flow);
            if (!filters?.flow || !option) {
              const modifier = { filters: { ...filters, flow: options?.[0]?.value }, offset: 0 };
              dispatch(setPaginationAction({ type, modifier }));
            }
          });

          return {
            loadflowFlowOptionsHash: {
              ...loadflowFlowOptionsHashSelector(getState()),
              [env || '']: options,
            },
          };
        }
      );
    }
);

interface ResponseData {
  product: 'Product';
  staging: 'Staging';
  develop: 'Develop';
}

export const fetchLoadflowEnvironmentOptionsAction = createAction(
  'options/FETCH_LOADFLOW_ENVIRONMENT',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'loadflowEnvironmentOptions'>> => {
      return AssetLifeAPI.get('gcp_workflows/env_dropdown').then((res: { data: [ResponseData] }) => {
        const types = [PaginationType.ADMIN_FERRETS, PaginationType.ADMIN_LOADFLOW];
        const options = (Object.keys(res.data[0]) as (keyof ResponseData)[]).map(key => ({
          value: key,
          label: res.data[0][key],
        }));

        // Automatically apply default values for necessary table filters
        types.forEach(type => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.environment);
          if (!filters?.environment || !option) {
            const modifier = { filters: { ...filters, environment: options?.[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          loadflowEnvironmentOptions: options,
        };
      });
    }
);

export const fetchLoadflowStepsOptionsAction = createAction(
  'options/FETCH_LOADFLOW_STEPS',
  async (flow: string, env: string) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'loadflowStepsOptionsHash'>> => {
      const state: State.Root = getState();
      return AssetLifeAPI.get('gcp_workflows/step_number_dropdown', { params: { flow_name: flow, env } }).then(
        (res: { data: string }) => ({
          loadflowStepsOptionsHash: { ...loadflowStepsOptionsHashSelector(state), [`${flow}${env}`]: res.data },
        })
      );
    }
);

export const fetchLoadflowFlowTypeOptionsAction = createAction(
  'options/FETCH_LOADFLOW_FLOW_TYPE',
  async (env: string) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'loadflowFlowTypeOptionsHash'>> => {
      return AssetLifeAPI.get('gcp_workflows/flow_type_dropdown', { params: { env } }).then(
        (res: { data: { id: string; name: string }[] }) => {
          const options = res.data.map(item => ({ value: item.id, label: item.name }));
          return {
            loadflowFlowTypeOptionsHash: { ...loadflowFlowTypeOptionsHashSelector(getState()), [env]: options },
          };
        }
      );
    }
);

export const fetchLoadflowPortfolioOptionsAction = createAction(
  'options/FETCH_LOADFLOW_PORTFOLIOS',
  async (tenant: string, env: string) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'loadflowPortfolioOptionsHash'>> => {
      const state: State.Root = getState();
      return AssetLifeAPI.get('gcp_workflows/portfolios_dropdown', { params: { tenant, env } }).then(
        (res: { data: { [key: number]: string }[] }) => {
          const options = res.data.map(item => ({
            value: Number(Object.keys(item)[0]),
            label: Object.values(item)[0],
          }));
          return {
            loadflowPortfolioOptionsHash: {
              ...loadflowPortfolioOptionsHashSelector(state),
              [`${tenant}${env}`]: options,
            },
          };
        }
      );
    }
);

export const fetchLoadflowScenarioOptionsAction = createAction(
  'options/FETCH_LOADFLOW_SCENARIOS',
  async (portfolio_id: Calculations.NewExecution['portfolio_id'], env: string) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'loadflowScenarioOptionsHash'>> => {
      const state: State.Root = getState();
      return AssetLifeAPI.get('gcp_workflows/scenarios_dropdown', { params: { portfolio_id, env } }).then(
        (res: { data: { [key: number]: string }[] }) => {
          const options = res.data.map(item => ({
            value: Number(Object.keys(item)[0]),
            label: Object.values(item)[0],
          }));
          return {
            loadflowScenarioOptionsHash: {
              ...loadflowScenarioOptionsHashSelector(state),
              [`${portfolio_id}${env}`]: options,
            },
          };
        }
      );
    }
);

export const fetchLoadflowInvestmentScenarioOptionsAction = createAction(
  'options/FETCH_LOADFLOW_INVESTMENT_SCENARIOS',
  async (scenario_id: Calculations.NewExecution['scenario_id'], env: string) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'loadflowInvestmentScenarioOptionsHash'>> => {
      const state: State.Root = getState();
      return AssetLifeAPI.get('gcp_workflows/investment_scenarios_dropdown', { params: { scenario_id, env } }).then(
        (res: { data: { [key: number]: string }[] }) => {
          const options = res.data.map(item => ({
            value: Number(Object.keys(item)[0]),
            label: Object.values(item)[0],
          }));
          return {
            loadflowInvestmentScenarioOptionsHash: {
              ...loadflowInvestmentScenarioOptionsHashSelector(state),
              [`${scenario_id}${env}`]: options,
            },
          };
        }
      );
    }
);

export const fetchDataHistoryTestOptionsAction = createAction(
  'options/FETCH_DATA_HISTORY_TESTS',
  async ({
    tenant,
    portfolio,
    scenario,
    key,
  }: {
    tenant: Layouts.Filters['tenant'];
    portfolio: Layouts.Filters['portfolio'];
    scenario: Layouts.Filters['scenario'];
    key: string;
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'dataHistoryTestOptionsHash'>> => {
      return AssetLifeAPI.get('monitoring/results_data_quality_tests', {
        params: { tenant, portfolio, scenario },
      }).then((res: { data: string[] }) => {
        const types = [PaginationType.ADMIN_DATA_HISTORY];
        const options = res.data.map(item => ({ value: item, label: item }));

        // Automatically apply default values for necessary table filters
        types.forEach(type => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.test);
          if (!filters?.test || !option) {
            const modifier = { filters: { ...filters, test: options?.[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          dataHistoryTestOptionsHash: {
            ...dataHistoryTestOptionsHashSelector(getState()),
            [key]: options,
          },
        };
      });
    }
);

export const fetchMeterDataIngestionTenantsOptionsAction = createAction(
  'options/FETCH_METER_DATA_INGESTION_TENANTS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'meterDataIngestionTenantsOptions'>> => {
      return AssetLifeAPI.get('monitoring/injection_metrics_tenants').then((res: { data: string[] }) => {
        const types = [PaginationType.ADMIN_METER_DATA_INGESTION];
        const options = res.data.map(item => ({ value: item, label: item }));

        // Automatically apply default values for necessary table filters
        types.forEach(type => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.tenant);
          if (!filters?.tenant || !option) {
            const modifier = { filters: { ...filters, tenant: options?.[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return { meterDataIngestionTenantsOptions: options };
      });
    }
);
