import Highcharts from 'highcharts';
import React, { useMemo } from 'react';
import Chart, { getBaseOptions } from '@utiligize/shared/components/Chart';

export interface ChartDataProps {
  series?: Shared.SeriesOptionsType[];
  title?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
}

const ChartVersionData: React.FC<ChartDataProps> = ({ title = '', xAxisTitle = '', yAxisTitle = '', series }) => {
  const options = useMemo(() => {
    const baseOptions = getBaseOptions(str => str, { title, xAxisTitle, yAxisTitle });
    return {
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        tickInterval: 1,
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `Version: <b>${this.x}</b><br>${this.points?.map(point => `${point.series.name} <b>${point.y}%</b><br>`).join('')}`;
        },
      },
      plotOptions: {
        series: {
          ...baseOptions.plotOptions.series,
          marker: {
            enabled: false,
          },
        },
      },
      series: series || [],
      legend: {
        enabled: series?.length ? series.length > 2 : false,
      },
    };
  }, [title, xAxisTitle, yAxisTitle, series]) as unknown as Highcharts.Options;

  return <Chart height="calc((100vh - 247px) / 2)" options={series ? options : null} />;
};

export default ChartVersionData;
