import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { usePaginationHandlers } from 'hooks';
import { DataTableTemplate } from '@utiligize/shared/components';
import TableFerretsModuleStatus from './TableFerretsModuleStatus';
import { SelectWorkflowTenant, SelectPortfolio } from 'components/_common';
import { FetchOptionsPageParam, PaginationType } from 'constants/index';

const ViewFerretsModuleStatus: React.FC = () => {
  const type = PaginationType.ADMIN_FERRETS_MODULE_STATUS;
  const { filters, handleSelectChange } = usePaginationHandlers({ type });

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectWorkflowTenant
            name="tenant"
            value={filters?.tenant}
            onChange={handleSelectChange}
            isClearable
            mutedTextLabelKey="Workflow tenant"
            blurInputOnSelect
            environment={filters?.environment || null}
            isDisabled={!filters?.environment}
            page={FetchOptionsPageParam.LOADFLOW}
          />
        </Col>
        <Col xs={2}>
          <SelectPortfolio
            name="portfolio"
            tenant={filters?.tenant}
            value={filters?.portfolio || null}
            onChange={handleSelectChange}
          />
        </Col>
      </Row>
      <TableFerretsModuleStatus />
    </DataTableTemplate>
  );
};

export default ViewFerretsModuleStatus;
