import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { dataStatusCountSelector, dataStatusItemsSelector } from 'modules/uptime/selectors';
import { fetchDataStatusAction } from 'modules/uptime';
import { dateFormat } from '@utiligize/shared/utils';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';
import StatusTableItem from './StatusTableItem';

const TableDataStatus: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_DATA_STATUS;
  const items = useSelector(dataStatusItemsSelector);
  const count = useSelector(dataStatusCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchDataStatusAction(params)).then(
        (action: Shared.ReduxAction<{ dataStatusItems: Uptime.Root['dataStatusItems'] }>) =>
          action.payload?.dataStatusItems
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 244px)"
    >
      {(items || []).map(item => {
        const id = item.name + item.tenant + item.first_simulation_id + item.first_test_run_timestamp;
        return (
          <tr key={id}>
            <td>{item.tenant}</td>
            <td>{item.portfolio}</td>
            <td>{item.scenario}</td>
            <td>{item.name}</td>
            <td className="text-nowrap">
              {item.first_test_run_timestamp ? dateFormat(item.first_test_run_timestamp, 'L LT') : '-'}
            </td>
            <td>{item.first_version_id}</td>
            <td>{item.first_simulation_id}</td>
            <td>
              <StatusTableItem id={id} {...item} />
            </td>
            <td>{item.message || '-'}</td>
          </tr>
        );
      })}
    </DataTable>
  );
};

export default TableDataStatus;
