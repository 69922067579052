import { handleActions } from 'redux-actions';
import {
  setLayoutAction,
  closeSidebarAction,
  toggleSidebarAction,
  setErrorToastAction,
  setInfoToastAction,
  setSuccessToastAction,
  deleteToastAction,
  setPaginationAction,
  setModalConfirmationMetaAction,
} from './actions';
import { _merge, _cloneDeep } from '@utiligize/shared/utils';
import { getStorageItem, setStorageItem } from 'utils';
import { MaxMobileWidth, PaginationType, StorageKeys } from 'constants/index';

const basePagination = { limit: 100, offset: 0, sort: 'DESC', column: 'createdAt', query: '' };

// Force local cache automatic clean if necessary
const currentVersion = 20240620;
const cachedVersion = getStorageItem(StorageKeys.PAGINATION_CACHE_VERSION);
if (Number(cachedVersion) !== currentVersion) {
  setStorageItem({ [StorageKeys.PAGINATION]: null });
  setStorageItem({ [StorageKeys.PAGINATION_CACHE_VERSION]: currentVersion });
}

export const initPagination: { [key in PaginationType]: Layouts.Pagination } = {
  [PaginationType.ADMIN_STATUS_OVERVIEW]: {} as Layouts.Pagination,
  [PaginationType.ADMIN_TEST_OVERVIEW]: {} as Layouts.Pagination,
  [PaginationType.ADMIN_FUNCTIONALITY_TESTS]: {
    ...basePagination,
    column: 'name',
    filters: {
      tenant: null,
      tags: null,
      success: false,
    },
  },
  [PaginationType.ADMIN_TEST_HISTORY]: {
    ...basePagination,
    column: 'test_run_timestamp',
    filters: {
      test: null,
      tenant: null,
      portfolio: null,
      scenario: null,
    },
  },
  [PaginationType.ADMIN_DATA_HISTORY]: {
    ...basePagination,
    column: 'test_run_timestamp',
    filters: {
      test: null,
      tenant: null,
      portfolio: null,
      scenario: null,
    },
  },
  [PaginationType.ADMIN_DATA_STATUS]: {
    ...basePagination,
    column: 'tenant',
    filters: {
      status: null,
      tenant: null,
      portfolio: null,
      scenario: null,
    },
  },
  [PaginationType.ADMIN_METER_DATA_INGESTION]: {
    ...basePagination,
    column: 'ingestion_date',
    filters: {
      tenant: null,
    },
  },
  [PaginationType.ADMIN_FERRETS]: {
    ...basePagination,
    filters: {
      environment: null,
      tenant: null,
    },
  },
  [PaginationType.ADMIN_FERRETS_MODULE_STATUS]: {
    ...basePagination,
    filters: {
      environment: 'product',
      tenant: null,
    },
  },

  [PaginationType.ADMIN_LOADFLOW]: {
    ...basePagination,
    filters: {
      flow: null,
      environment: null,
      tenant: null,
      single_primary_substation_flow: false,
    },
  },

  [PaginationType.ADMIN_VERSION_DETAILS]: {
    ...basePagination,
    filters: {
      tenant: null,
      portfolio: null,
    },
  },
};

const cachedPagination: any = getStorageItem(StorageKeys.PAGINATION);
const pagination = _merge(_cloneDeep(initPagination), cachedPagination);

const initialState: Layouts.Root = {
  isSidebarOpen: window.innerWidth > MaxMobileWidth,
  toasts: [],
  pagination,
  modalConfirmationMeta: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [setLayoutAction.toString()]: (
    state: Layouts.Root,
    action: Shared.ReduxAction<Partial<Layouts.Root>>
  ): Layouts.Root => ({
    ...state,
    ...action.payload,
  }),

  [closeSidebarAction.toString()]: (state: Layouts.Root): Layouts.Root => ({
    ...state,
    isSidebarOpen: false,
  }),

  [toggleSidebarAction.toString()]: (state: Layouts.Root): Layouts.Root => ({
    ...state,
    isSidebarOpen: !state.isSidebarOpen,
  }),

  [setErrorToastAction.toString()]: (state: Layouts.Root, action: Shared.ReduxAction<string>): Layouts.Root => ({
    ...state,
    toasts: [...state.toasts, { id: Date.now(), type: 'error', message: action.payload }],
  }),

  [setInfoToastAction.toString()]: (state: Layouts.Root, action: Shared.ReduxAction<string>): Layouts.Root => ({
    ...state,
    toasts: [...state.toasts, { id: Date.now(), type: 'info', message: action.payload }],
  }),

  [setSuccessToastAction.toString()]: (state: Layouts.Root, action: Shared.ReduxAction<string>): Layouts.Root => ({
    ...state,
    toasts: [...state.toasts, { id: Date.now(), type: 'success', message: action.payload }],
  }),

  [deleteToastAction.toString()]: (state: Layouts.Root, action: Shared.ReduxAction<number>): Layouts.Root => ({
    ...state,
    toasts: state.toasts.filter(toast => toast.id !== action.payload),
  }),

  [setPaginationAction.toString()]: (
    state: Layouts.Root,
    action: Shared.ReduxAction<{ type: Type.PaginationType; modifier: Partial<Layouts.Pagination> }>
  ): Layouts.Root => {
    const pagination = {
      ...state.pagination,
      [action.payload.type]: { ...state.pagination[action.payload.type], ...action.payload.modifier },
    };
    setStorageItem({ [StorageKeys.PAGINATION]: pagination });
    return { ...state, pagination };
  },

  [setModalConfirmationMetaAction.toString()]: (
    state: Layouts.Root,
    action: Shared.ReduxAction<Shared.ModalConfirmationMeta>
  ): Layouts.Root => ({
    ...state,
    modalConfirmationMeta: action.payload && {
      ...action.payload,
    },
  }),
};

export {
  setLayoutAction,
  closeSidebarAction,
  toggleSidebarAction,
  setErrorToastAction,
  setInfoToastAction,
  setSuccessToastAction,
  deleteToastAction,
  setPaginationAction,
  setModalConfirmationMetaAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
