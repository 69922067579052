import React, { useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { Modal, HiddenFormSubmit, Button, FormInput, Checkbox } from '@utiligize/shared/components';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik, { getMiscString } from './useConfiguredFormik';
import SelectFlow from '../SelectFlow';
import { SelectEnvironment, SelectWorkflowTenant } from 'components/_common';
import StepsInput from './StepsInput';
import SelectFlowType from './SelectFlowType';
import SelectPortfolio from './SelectPortfolio';
import SelectScenario from './SelectScenario';
import SelectInvestmentScenario from './SelectInvestmentScenario';
import { FetchOptionsPageParam } from 'constants/index';

const ModalNewExecution: React.FC = () => {
  const [show, setShow] = useState(false);
  const toggleModal = useCallback((): void => setShow(show => !show), []);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    setFieldTouched,
    submitForm,
    setFieldValue,
    handleChange,
    handleBlur,
  } = useConfiguredFormik({
    toggleModal,
  });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption, { name }: Type.SelectActionMetaBase) => {
      const value = Array.isArray(option) ? option.map(i => i.value) : option?.value;
      if (name === 'flow') setFieldValue('steps', '');
      if (name === 'environment' || name === 'tenant') {
        setFieldValue('flow_type', null);
        setFieldValue('portfolio_id', null);
        setFieldValue('scenario_id', null);
        setFieldValue('investment_scenario_id', null);
        setFieldValue('misc', '');
      }
      if (name === 'flow_type') {
        setFieldValue('misc', '');
      }
      if (name === 'portfolio_id') {
        setFieldValue('scenario_id', null);
        setFieldValue('investment_scenario_id', null);
        setFieldValue('misc', '');
      }
      if (name === 'scenario_id') {
        setFieldValue('investment_scenario_id', null);
        setFieldValue('misc', '');
      }
      if (name === 'investment_scenario_id') setFieldValue('misc', '');
      setFieldValue(name, value ?? null);
    },
    [setFieldValue]
  );

  return (
    <>
      <Button
        disabled={Boolean(!values?.tenant)}
        icon={<IconPlus />}
        labelKey="Start a new execution"
        onClick={toggleModal}
        variant="primary"
        tooltipKey={!values?.tenant ? 'Please select workflow tenant' : ''}
      />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey="Start a new execution"
        cancelButtonProps={{ disabled: isSubmitting, onClick: toggleModal }}
        submitButtonProps={{ labelKey: 'Start execution', loading: isSubmitting, onClick: handleFormSubmit }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <SelectEnvironment
              name="environment"
              value={values?.environment || null}
              onChange={handleSelectChange}
              labelKey="Environment"
            />
          </Form.Group>
          <Form.Group>
            <SelectFlow
              name="flow"
              value={values?.flow || null}
              onChange={handleSelectChange}
              labelKey="Flow"
              environment={values?.environment}
              isDisabled={!values?.environment}
            />
          </Form.Group>
          <Form.Group>
            <SelectWorkflowTenant
              name="tenant"
              value={values?.tenant}
              onChange={handleSelectChange}
              labelKey="Workflow tenant"
              onBlur={() => setFieldTouched('tenant')}
              errorKey={Boolean(touched.tenant && errors.tenant) ? (errors.tenant as string) : ''}
              environment={values?.environment}
              isDisabled={!values?.environment}
              page={FetchOptionsPageParam.LOADFLOW}
            />
          </Form.Group>
          <Form.Group>
            <StepsInput
              name="steps"
              value={values?.steps}
              onChange={handleChange}
              onBlur={() => setFieldTouched('steps')}
              errorKey={Boolean(touched.steps && errors.steps) ? (errors.steps as string) : ''}
              flow={values?.flow}
              environment={values?.environment}
              disabled={!values?.flow || !values?.environment}
            />
          </Form.Group>
          <Form.Group>
            <SelectFlowType
              name="flow_type"
              value={values?.flow_type}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('flow_type')}
              errorKey={Boolean(touched.flow_type && errors.flow_type) ? (errors.flow_type as string) : ''}
              isClearable
              environment={values?.environment}
              isDisabled={!values?.environment}
            />
          </Form.Group>
          <Form.Group>
            <SelectPortfolio
              name="portfolio_id"
              value={values?.portfolio_id}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('portfolio_id')}
              errorKey={Boolean(touched.portfolio_id && errors.portfolio_id) ? (errors.portfolio_id as string) : ''}
              tenant={values?.tenant}
              environment={values?.environment}
              isDisabled={!values?.tenant || !values?.environment}
              isAutoSelectionEnabled
            />
          </Form.Group>
          <Form.Group>
            <SelectScenario
              name="scenario_id"
              value={values?.scenario_id}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('scenario_id')}
              errorKey={Boolean(touched.scenario_id && errors.scenario_id) ? (errors.scenario_id as string) : ''}
              portfolio_id={values?.portfolio_id}
              environment={values?.environment}
              isDisabled={!values?.portfolio_id || !values?.environment}
              isAutoSelectionEnabled
            />
          </Form.Group>
          <Form.Group>
            <SelectInvestmentScenario
              name="investment_scenario_id"
              value={values?.investment_scenario_id}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('investment_scenario_id')}
              errorKey={
                Boolean(touched.investment_scenario_id && errors.investment_scenario_id)
                  ? (errors.investment_scenario_id as string)
                  : ''
              }
              scenario_id={values?.scenario_id}
              environment={values?.environment}
              isDisabled={!values?.scenario_id || !values?.environment}
              isAutoSelectionEnabled
            />
          </Form.Group>
          <Form.Group>
            <Checkbox
              labelKey="Partial run"
              className="icheck-primary"
              name="partial_run"
              checked={values?.partial_run}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Checkbox
              labelKey="Single substation flow"
              className="icheck-primary"
              name="single_substation_flow"
              checked={values?.single_substation_flow}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Misc"
              name="misc"
              value={getMiscString(values)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalNewExecution;
