import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { usePaginationHandlers } from 'hooks';
import { getVersionChartData } from 'modules/calculations';
import { DataTableTemplate, ContentContainer, ContentCard } from '@utiligize/shared/components';
import { SelectPortfolio } from 'components/_common';
import { Colors } from '@utiligize/shared/components/Chart';
import ChartVersionData, { ChartDataProps } from './ChartVersionData';
import SelectWorkflowTenant from 'components/_common/SelectWorkflowTenant';
import { FetchOptionsPageParam, PaginationType } from 'constants/index';

const ViewVersionDetails: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.ADMIN_VERSION_DETAILS;
  const { filters, handleSelectChange } = usePaginationHandlers({ type });
  const [chartData, setChartData] = useState<ChartDataProps[] | null>(null);

  useEffect(() => {
    setChartData(null);
    if (!filters?.tenant || !filters.portfolio) return;
    dispatch(getVersionChartData(filters?.tenant, filters?.portfolio))
      .then(
        (action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x' | 'categories'>[] | null>) => {
          setChartData(
            action.payload?.map(chart => ({
              series: chart.series.map((i, index: number) => ({
                name: i.group || '',
                type: 'line' as any,
                data: i.data,
                color: Colors[index],
              })),
              title: chart.title,
              xAxisTitle: chart.xaxis_title,
              yAxisTitle: chart.yaxis_title,
            })) || []
          );
        }
      )
      .catch(() => setChartData([{ series: [] }, { series: [] }]));
  }, [dispatch, filters?.tenant, filters?.portfolio]);

  const handleSelectWorkflowTenantChange = useCallback(
    (option: Type.SelectOption, meta: Type.SelectActionMetaBase) => {
      handleSelectChange(option, meta, { portfolio: null });
    },
    [handleSelectChange]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectWorkflowTenant
            environment="all"
            name="tenant"
            value={filters?.tenant}
            onChange={handleSelectWorkflowTenantChange}
            blurInputOnSelect
            page={FetchOptionsPageParam.VERSION_DETAILS}
            mutedTextLabelKey="Tenant"
          />
        </Col>
        <Col xs={2}>
          <SelectPortfolio
            name="portfolio"
            tenant={filters?.tenant}
            value={filters?.portfolio || null}
            onChange={handleSelectChange}
          />
        </Col>
      </Row>
      <ContentContainer>
        <ContentCard marginBottom>
          <ChartVersionData {...(chartData?.[0] || {})} />
        </ContentCard>
        <ContentCard>
          <ChartVersionData {...(chartData?.[1] || {})} />
        </ContentCard>
      </ContentContainer>
    </DataTableTemplate>
  );
};

export default ViewVersionDetails;
