import { createSelector } from 'reselect';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import {
  dataHistoryTestOptionsSelectorFactory,
  meterDataIngestionTenantsOptionsSelector,
  portfolioOptionsSelectorFactory,
  scenarioOptionsSelectorFactory,
  tenantsOptionsSelector,
  testsOptionsSelector,
} from 'modules/options/selectors';
import { PaginationType } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.uptime;

export const functionalityTestsCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['functionalityTestsCount'] => uptime.functionalityTestsCount
);

const functionalityTestsHashSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['functionalityTestsHash'] => uptime.functionalityTestsHash
);

export const functionalityTestsSelector = createSelector(
  functionalityTestsHashSelector,
  (functionalityTestsHash: Uptime.Root['functionalityTestsHash']): Uptime.Test[] =>
    Object.values(functionalityTestsHash)
);

export const testOverviewsHashSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testOverviewsHash'] => uptime.testOverviewsHash
);

export const testOverviewsLastTestRunTimestampSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testOverviewsLastTestRunTimestamp'] => uptime.testOverviewsLastTestRunTimestamp
);

export const testHistoryCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testHistoryCount'] => uptime.testHistoryCount
);

export const testHistoryItemsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['testHistoryItems'] => uptime.testHistoryItems
);

export const statusOverviewsHashSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['statusOverviewsHash'] => uptime.statusOverviewsHash
);

export const dataHistoryCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['dataHistoryCount'] => uptime.dataHistoryCount
);

export const dataHistoryItemsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['dataHistoryItems'] => uptime.dataHistoryItems
);

export const dataStatusCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['dataStatusCount'] => uptime.dataStatusCount
);

export const dataStatusItemsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['dataStatusItems'] => uptime.dataStatusItems
);

export const tableDataHistoryWaitForDependenciesSelectorFactory = ({
  tenant,
  portfolio,
  scenario,
}: {
  tenant: Layouts.Filters['tenant'];
  portfolio: Layouts.Filters['portfolio'];
  scenario: Layouts.Filters['scenario'];
}) =>
  createSelector(
    [
      dataHistoryTestOptionsSelectorFactory(`${tenant}_${portfolio}_${scenario}`),
      tenantsOptionsSelector,
      portfolioOptionsSelectorFactory(tenant),
      scenarioOptionsSelectorFactory(`${tenant}_${portfolio}`),
    ],
    (dataHistoryTestOptions, tenantsOptions, portfolioOptions, scenarioOptions): boolean => {
      return Boolean(!dataHistoryTestOptions || !tenantsOptions || !portfolioOptions || !scenarioOptions);
    }
  );

export const tableTestHistoryWaitForDependenciesSelectorFactory = ({
  tenant,
  portfolio,
}: {
  tenant: Layouts.Filters['tenant'];
  portfolio: Layouts.Filters['portfolio'];
}) =>
  createSelector(
    [
      testsOptionsSelector,
      tenantsOptionsSelector,
      portfolioOptionsSelectorFactory(tenant),
      scenarioOptionsSelectorFactory(`${tenant}_${portfolio}`),
    ],
    (testsOptions, tenantsOptions, portfolioOptions, scenarioOptions): boolean => {
      return Boolean(
        !testsOptions || !tenantsOptions || (tenant && !portfolioOptions) || (tenant && portfolio && !scenarioOptions)
      );
    }
  );

export const meterDataIngestionCountSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['meterDataIngestionCount'] => uptime.meterDataIngestionCount
);

export const meterDataIngestionItemsSelector = createSelector(
  rootSelector,
  (uptime: Uptime.Root): Uptime.Root['meterDataIngestionItems'] => uptime.meterDataIngestionItems
);

export const tableMeterDataIngestionWaitForDependenciesSelector = createSelector(
  [paginationSelectorFactory(PaginationType.ADMIN_METER_DATA_INGESTION), meterDataIngestionTenantsOptionsSelector],
  ({ filters }, options): boolean => Boolean(!filters?.tenant || !options)
);
