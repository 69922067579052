import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ferretsModuleStatusSelector,
  tableFerretsModulaStatusWaitForDependenciesSelectorFactory,
} from 'modules/calculations/selectors';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { fetchFerretsModuleStatusAction } from 'modules/calculations';
import { ContentContainer, Spinner } from '@utiligize/shared/components';
import { dateFormat } from '@utiligize/shared/utils';
import { PaginationType } from 'constants/index';

const TableFerretsModuleStatus: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  const type = PaginationType.ADMIN_FERRETS_MODULE_STATUS;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const ferretsModuleStatus = useSelector(ferretsModuleStatusSelector);

  const waitForDependenciesSelector = useMemo(
    () =>
      tableFerretsModulaStatusWaitForDependenciesSelectorFactory({
        environment: filters?.environment,
        tenant: filters?.tenant,
      }),
    [filters?.environment, filters?.tenant]
  );
  const waitForDependencies = useSelector(waitForDependenciesSelector);

  const sendRequest = useCallback(() => {
    setLoading(true);
    dispatch(fetchFerretsModuleStatusAction()).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (filters?.environment && filters?.tenant && !waitForDependencies) sendRequest();
  }, [waitForDependencies, filters?.environment, filters?.tenant, sendRequest]);

  return (
    <ContentContainer>
      {loading || waitForDependencies ? (
        <Spinner inline />
      ) : (
        <>
          {ferretsModuleStatus && (
            <table className="table table-bordered bg-white">
              {Object.keys(ferretsModuleStatus).map(key => (
                <tr>
                  <td>{key}</td>
                  <td>{dateFormat(ferretsModuleStatus[key], 'lll')}</td>
                </tr>
              ))}
            </table>
          )}
        </>
      )}
    </ContentContainer>
  );
};

export default TableFerretsModuleStatus;
