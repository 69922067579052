import React from 'react';
import { Route, Switch } from 'react-router';
import { Routes } from 'constants/index';
import ViewDataHistory from './ViewDataHistory';
import ViewDataStatus from './ViewDataStatus';

const ViewData: React.FC = () => (
  <Switch>
    <Route exact path={Routes.Data} component={ViewDataHistory} />
    <Route exact path={Routes.DataStatus} component={ViewDataStatus} />
  </Switch>
);

export default ViewData;
