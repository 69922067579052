import { createAction } from 'redux-actions';
import queryString from 'query-string';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const resetReduxStateAction = createAction(
  'uptime/RESET_REDUX_STATE',
  (nextState: Partial<Uptime.Root>) => nextState
);

export const fetchTestOverviewsAction: any = createAction(
  'uptime/FETCH_TEST_OVERVIEWS',
  async () => (): Promise<Pick<Uptime.Root, 'testOverviewsHash' | 'testOverviewsLastTestRunTimestamp'>> => {
    return AssetLifeAPI.get('monitoring/dashboard_health_overview').then(res => {
      const testOverviewsHash = res.data.rows.reduce((acc: any, item: Uptime.TestOverview) => {
        if (!acc[item.tenant]) acc[item.tenant] = {};
        if (!acc[item.tenant][item.portfolio]) acc[item.tenant][item.portfolio] = [];
        acc[item.tenant][item.portfolio].push(item);
        return acc;
      }, {});

      return {
        testOverviewsHash,
        testOverviewsLastTestRunTimestamp: res.data.meta?.timestamp_last_test_run,
      };
    });
  }
);

export const runTenantTestsAction: any = createAction(
  'uptime/RUN_TENANT_TESTS',
  async (tenant?: string) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.post('metrics/run_ui_test', tenant ? { tenants: [tenant] } : undefined).then(() => {
        dispatch(setSuccessToastAction('Tests successfully started'));
      });
    }
);

export const fetchFunctionalityTestsAction: any = createAction(
  'uptime/FETCH_FUNCTIONALITY_TESTS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Uptime.Root, 'functionalityTestsCount' | 'functionalityTestsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.ADMIN_FUNCTIONALITY_TESTS
      )(state);
      return AssetLifeAPI.get('monitoring/selenium_tests', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          tenant: filters?.tenant,
          tags: filters?.tags,
          success: filters?.success,
        },
        paramsSerializer: params => queryString.stringify(params),
      }).then((res: any) => ({
        functionalityTestsCount: res.data.count,
        functionalityTestsHash: _keyBy(res.data.rows, (item: Uptime.Test) => item.test_run_timestamp),
        skipStoreUpdate,
      }));
    }
);

export const fetchTestHistoryAction: any = createAction(
  'uptime/FETCH_TEST_HISTORY',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Uptime.Root, 'testHistoryCount' | 'testHistoryItems'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.ADMIN_TEST_HISTORY
      )(state);
      return AssetLifeAPI.get('monitoring/dashboard_test_history', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          test: filters?.test,
          tenant: filters?.tenant,
          portfolio: filters?.portfolio,
          scenario: filters?.scenario,
        },
      }).then((res: any) => ({
        testHistoryCount: res.data.count,
        testHistoryItems: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const fetchStatusOverviewsAction: any = createAction(
  'uptime/FETCH_STATUS_OVERVIEWS',
  async () => (): Promise<Pick<Uptime.Root, 'statusOverviewsHash'>> => {
    return AssetLifeAPI.get('monitoring/last_version_simulation').then(res => {
      const statusOverviewsHash = res.data.rows.reduce((acc: any, item: Uptime.StatusOverview) => {
        if (!acc[item.tenant]) acc[item.tenant] = {};
        if (!acc[item.tenant][item.portfolio]) acc[item.tenant][item.portfolio] = [];
        acc[item.tenant][item.portfolio].push(item);
        return acc;
      }, {});

      return { statusOverviewsHash };
    });
  }
);

export const fetchDataHistoryAction: any = createAction(
  'uptime/FETCH_DATA_HISTORY',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Uptime.Root, 'dataHistoryCount' | 'dataHistoryItems'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.ADMIN_DATA_HISTORY
      )(state);
      return AssetLifeAPI.get('monitoring/results_data_quality_history', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          test_name: filters?.test,
          tenant: filters?.tenant,
          portfolio: filters?.portfolio,
          scenario: filters?.scenario,
        },
      }).then((res: any) => ({
        dataHistoryCount: res.data.count,
        dataHistoryItems: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const fetchDataStatusAction: any = createAction(
  'uptime/FETCH_DATA_STATUS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Uptime.Root, 'dataStatusCount' | 'dataStatusItems'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.ADMIN_DATA_STATUS
      )(state);
      return AssetLifeAPI.get('monitoring/failed_data_tests', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          tenant: filters?.tenant,
          portfolio: filters?.portfolio,
          scenario: filters?.scenario,
          status: filters?.status,
        },
      }).then((res: any) => ({
        dataStatusCount: res.data.count,
        dataStatusItems: res.data.rows,
        skipStoreUpdate,
      }));
    }
);

export const setDataStatusItemStatusAction = createAction(
  'uptime/SET_DATA_STATUS_ITEM_STATUS',
  async ({ name, first_simulation_id, message, status, first_test_run_timestamp }: Partial<Uptime.DataStatusItem>) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.put('monitoring/failed_data_tests', {
        name,
        first_simulation_id,
        message,
        status,
        first_test_run_timestamp,
      }).then(async () => {
        await dispatch(fetchDataStatusAction());
        dispatch(setSuccessToastAction('Data status has been updated'));
      });
    }
);

export const fetchMeterDataIngestionAction: any = createAction(
  'uptime/FETCH_METER_DATA_INGESTION',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Uptime.Root, 'meterDataIngestionCount' | 'meterDataIngestionItems'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.ADMIN_METER_DATA_INGESTION
      )(state);
      return AssetLifeAPI.get('monitoring/injection_metrics', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          tenant: filters?.tenant,
        },
      }).then((res: any) => ({
        meterDataIngestionCount: res.data.count,
        meterDataIngestionItems: res.data.rows,
        skipStoreUpdate,
      }));
    }
);
