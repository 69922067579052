import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLoadflowStepsOptionsAction } from 'modules/options';
import { loadflowStepsOptionsSelectorFactory } from 'modules/options/selectors';
import { FormInput, FormInputProps } from '@utiligize/shared/components';

interface Props extends FormInputProps {
  value: Calculations.NewExecution['steps'];
  flow: Calculations.NewExecution['flow'];
  environment: Layouts.Filters['environment'] | null;
}

const StepsInput: React.FC<Props> = ({ name, value, flow, environment, onChange, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const optionsSelector = useMemo(
    () => loadflowStepsOptionsSelectorFactory(`${flow}${environment}`),
    [flow, environment]
  );
  const options = useSelector(optionsSelector);

  useEffect(() => {
    if (!flow || !environment || options) return;
    setIsLoading(true);
    dispatch(fetchLoadflowStepsOptionsAction(flow, environment)).finally(() => setIsLoading(false));
  }, [flow, environment, options, dispatch]);

  useEffect(() => {
    onChange?.({ target: { name, value: options } } as React.ChangeEvent<HTMLInputElement>);
  }, [options, name, onChange]);

  return (
    <FormInput {...props} disabled={isLoading} name={name} value={value} labelKey="Steps" isNew onChange={onChange} />
  );
};
export default StepsInput;
