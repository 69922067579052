import Highcharts from 'highcharts';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { testHistoryItemsSelector } from 'modules/uptime/selectors';
import Chart, { getBaseOptions } from '@utiligize/shared/components/Chart';
import { dateFormat } from '@utiligize/shared/utils';

interface ChartDataProps {
  series: any[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

const ChartHistory: React.FC = () => {
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const items = useSelector(testHistoryItemsSelector);
  const categories = useMemo(() => items?.map(item => dateFormat(item.test_run_timestamp, 'L LT')).reverse(), [items]);

  useEffect(() => {
    if (!items) return;
    setChartData(null);
    setChartData({
      series: [
        {
          name: '',
          type: 'line' as any,
          lineWidth: 3,
          color: 'lightgray',
          data: items
            .map(item => ({
              y: Number(item.success),
              duration: item.duration,
              version: item.dashboard_version,
              color: item.success ? 'green' : '#ff0000',
            }))
            .reverse(),
        },
      ],
      title: '',
      xAxisTitle: '',
      yAxisTitle: '',
    });
  }, [items]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions((str: string) => str, chartData);
    return {
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        minTickInterval: 1,
        categories,
        labels: {
          style: {
            whiteSpace: 'nowrap', // Prevent text from wrapping
          },
        },
      },
      yAxis: {
        ...baseOptions.yAxis,
        tickInterval: 1,
        max: 1,
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
      },
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          const { version, duration } = this.point as any;
          return `${this.x}<br><b>Success:</b> ${this.y ? 'Yes' : 'No'}<br><b>Dashboard version:</b> ${version || '-'}<br><b>Duration:</b> ${duration || '-'}`;
        },
      },
      exporting: {
        enabled: false,
      },
      series: chartData?.series,
      legend: { enabled: false },
    };
  }, [chartData, categories]) as unknown as Highcharts.Options;

  return <Chart height="20vh" options={chartData ? options : null} />;
};

export default ChartHistory;
